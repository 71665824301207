(function () {
	var $btn	= document.querySelector('#mbl__toggler');
	var $menu 	= document.querySelector('.topo__main');
	var $topo   = document.querySelector('.topo');

	$btn.addEventListener('click', function(event) {
		event.preventDefault();

		// Adicionando as classes do estado "active"
		$menu.classList.add('topo__main--shown');
		$btn.classList.add('mbl__toggler--active');

		// Setando o aria-expanded como "true"
		$btn.setAttribute('aria-expanded', true);

		// Criando a div de fundo do menu
		var $bg = document.createElement('div');

		$bg.classList.add('bg__menu');

		// Adicionando um evento de click para o $bg
		$bg.addEventListener('click', function() {
			$bg.classList.add('hide');

			// Removendo as classes do estado "active" quando $bg for clicado
			$menu.classList.remove('topo__main--shown');
			$btn.classList.remove('mbl__toggler--active');

			// Setando o aria-expanded como "false"
			$btn.setAttribute('aria-expanded', false);

			// Removendo o $bg quando a animação de fadeOut terminar
			$bg.addEventListener('animationend', function() {
				$topo.removeChild($bg);
			});
		});

		// Printando o $bg na tela
		$topo.appendChild($bg);
	});
})();
(function () {
	var $btn	= document.querySelector('#pesquisa__toggler');
	var $menu 	= document.querySelector('.segura__pesquisa');
	var $topo   = document.querySelector('.topo');

	$btn.addEventListener('click', function(event) {
		event.preventDefault();

		// Adicionando as classes do estado "active"
		$menu.classList.add('segura__pesquisa--shown');
		$btn.classList.add('pesquisa__toggler--active');

		// Setando o aria-expanded como "true"
		$btn.setAttribute('aria-expanded', true);

		// Criando a div de fundo do menu
		var $bg = document.createElement('div');

		$bg.classList.add('bg__menu');

		// Adicionando um evento de click para o $bg
		$bg.addEventListener('click', function() {
			$bg.classList.add('hide');

			// Removendo as classes do estado "active" quando $bg for clicado
			$menu.classList.remove('segura__pesquisa--shown');
			$btn.classList.remove('pesquisa__toggler--active');

			// Setando o aria-expanded como "false"
			$btn.setAttribute('aria-expanded', false);

			// Removendo o $bg quando a animação de fadeOut terminar
			$bg.addEventListener('animationend', function() {
				$topo.removeChild($bg);
			});
		});

		// Printando o $bg na tela
		$topo.appendChild($bg);
	});
})();

(function () {
	var $btn	= document.querySelector('#pesquisa__toggler2');
	var $menu 	= document.querySelector('.segura__pesquisa');
	var $topo   = document.querySelector('.topo');

	$btn.addEventListener('click', function(event) {
		event.preventDefault();

		// Adicionando as classes do estado "active"
		$menu.classList.add('segura__pesquisa--shown');
		$btn.classList.add('pesquisa__toggler2--active');

		// Setando o aria-expanded como "true"
		$btn.setAttribute('aria-expanded', true);

		// Criando a div de fundo do menu
		var $bg = document.createElement('div');

		$bg.classList.add('bg__menu');

		// Adicionando um evento de click para o $bg
		$bg.addEventListener('click', function() {
			$bg.classList.add('hide');

			// Removendo as classes do estado "active" quando $bg for clicado
			$menu.classList.remove('segura__pesquisa--shown');
			$btn.classList.remove('pesquisa__toggler2--active');

			// Setando o aria-expanded como "false"
			$btn.setAttribute('aria-expanded', false);

			// Removendo o $bg quando a animação de fadeOut terminar
			$bg.addEventListener('animationend', function() {
				$topo.removeChild($bg);
			});
		});

		// Printando o $bg na tela
		$topo.appendChild($bg);
	});
})();
